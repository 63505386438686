'use client';

import { FaDumbbell, FaGear, FaMoon, FaSun, FaUser } from 'react-icons/fa6';
import { SignInButton, UserButton } from '@clerk/nextjs';

import { BsCalendar3 } from 'react-icons/bs';
import { FcSynchronize } from 'react-icons/fc';
import Image from 'next/image';
import { RxActivityLog } from 'react-icons/rx';
import { ToastContainer } from 'react-toastify';
import { cn } from '@/utils/helper';
import { trpc } from '@/app/api/trpc/client';
import { useTheme } from '@/app/components/Theme';
import { useUser } from '@clerk/clerk-react';

const Header = () => {
  const { mutate: syncStrava, isPending } = trpc.strava.sync.useMutation();
  const { isSignedIn } = useUser();
  const { isDarkMode, toggleDarkMode } = useTheme();

  const navItems = [
    {
      href: '#',
      onClick: () => syncStrava({ fromDaysAgo: 0 }),
      icon: (
        <FcSynchronize
          className={cn([
            isPending ? 'icon loading-icon' : 'icon',
            'w-5 h-5 cursor-pointer',
          ])}
        />
      ),
      label: 'Sync Latest',
      public: false,
    },
    {
      href: '/calendar',
      icon: <BsCalendar3 className='icon' />,
      label: 'Calendar',
      public: false,
    },
    {
      href: '/activities',
      icon: <RxActivityLog className='icon' />,
      label: 'Activities',
      public: false,
    },
    {
      href: '/workouts',
      icon: <FaDumbbell className='icon' />,
      label: 'Workouts',
      public: false,
    },
    {
      href: '/settings',
      icon: <FaGear className='icon' />,
      label: 'Settings',
      public: false,
    },
  ];

  return (
    <header className='bg-gray-400 dark:bg-gray-900 text-gray-900 dark:text-gray-100 p-2 fixed top-0 left-0 right-0 z-50'>
      <nav className='flex justify-between items-center'>
        <a href='/' className='font-normal flex items-center gap-4'>
          <div className='bg-emerald-600 rounded-full w-6 h-6'>
            <Image src='/trainme.webp' alt='Logo' width={48} height={48} />
          </div>
          <div className='text-blue-100 font-semibold'>TrainMe</div>
        </a>
        <div className='flex gap-2 items-center'>
          {isSignedIn && (
            <ul className='flex gap-2 items-center'>
              {navItems.map(
                (item, index) =>
                  (item.public || isSignedIn) && (
                    <li key={index}>
                      <a
                        href={item.href}
                        onClick={item.onClick}
                        className='hover:underline flex gap-2 items-center btn btn-link'
                      >
                        {item.icon}
                        <span className=''>{item.label}</span>
                      </a>
                    </li>
                  )
              )}
            </ul>
          )}
          <button
            onClick={toggleDarkMode}
            className='hover:underline flex gap-2 items-center btn btn-link'
          >
            {isDarkMode ? (
              <FaSun className='icon text-yellow-300 hover:text-yellow-200' />
            ) : (
              <FaMoon className='icon text-blue-700 hover:text-blue-800' />
            )}
          </button>
          <div className='flex gap-1 items-center'>
            {!!isSignedIn ? (
              <UserButton
                appearance={{
                  elements: {
                    avatarBox: 'h-6 w-6',
                  },
                }}
              />
            ) : (
              <SignInButton mode='modal'>
                <button className='btn btn-link'>
                  <FaUser className='icon' />
                </button>
              </SignInButton>
            )}
          </div>
        </div>
      </nav>
      <ToastContainer
        theme={isDarkMode ? 'dark' : 'light'}
        autoClose={7 * 1000}
        position='top-right'
        hideProgressBar={false}
        closeOnClick={false}
        pauseOnHover={false}
        draggable={true}
        pauseOnFocusLoss={false}
        className='toast-container'
        // Update key to force re-render on theme change
        key={isDarkMode ? 'dark' : 'light'}
      />
    </header>
  );
};

export default Header;
