import type { Activity, Analysis, Workout } from '@trainme/db';
import type { StravaLap } from '@trainme/db';

export type MapField = {
  id: string;
  polyline?: string;
  resourceState: number;
  summaryPolyline?: string;
};

export type ActivityWithRelations = Activity & { laps?: StravaLap[] };
export type WorkoutWithRelations = Workout & { analysis?: Analysis };

// The calendar view, lowercase to match the URL params
export enum View {
  Week = 'week',
  Month = 'month',
  Day = 'day',
}
